import React, { useState } from 'react';
import styled from 'styled-components';

const OrderFormWrapper = styled.div`
`;

const Bold = styled.span`
  font-weight: 800;
`;

const Shipping = ({ shipping }) => {
    if (shipping === 0) {
        return (
            <Bold>besplatna</Bold>
        );
    }
    return (
        <span>320 rsd</span>
    );
};

const ProductsList = ({ items = [], onAction = () => {} }) => {
  const total = items.reduce((sum, item) => {
    return sum + (item.product.discount_price || item.product.price) * item.quantity;
  }, 0);
  if (items.length === 0) {
    return (
      <div>No products</div>
    );
  }
  const shipping = total >= 5000 ? 0 : 320;
  return (
    <React.Fragment>
      <table className="table">
        <thead>
          <tr>
            <th style={{display: 'none'}}>Id</th>
            <th></th>
            <th>Proizvod</th>
            <th>Cena</th>
            <th>Komada</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            return (
              <tr key={index}>
                <td className="product-icon">
                  <img
                    src={item.product.icon_url}
                    alt={item.product.name}
                    title={item.product.excerpt}
                  />
                </td>
                <td className="product-name">{item.product.name}</td>
                {item.product.discount_price ? (
                  <td className="product-price">
                    <span className="product-price-check-old">
                      {item.product.price}
                    </span>
                    <span className="product-price-check-new">
                      {item.product.discount_price || item.product.price}
                    </span>
                  </td>
                ) : (
                  <td className="product-price">
                    <span className="product-price-check-new">
                      {item.product.price}
                    </span>
                  </td>
                )}
                <td className="product-qty">
                  <button
                    type="button"
                    className="btn btn-default btn-xs"
                    onClick={() => onAction('onDec', item)}
                  >-</button>
                  <strong className="quantity">{item.quantity}</strong>
                  <button
                    type="button"
                    className="btn btn-default btn-xs"
                    onClick={() => onAction('onInc', item)}
                  >+</button>
                </td>
                <td className="product-delete">
                  <button
                    type="button"
                    className="btn btn-default btn-xs"
                    onClick={() => onAction('onRemove', item)}
                  >
                    <i className="fa fa-trash-o"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <hr/>
      <ul className="cart-total-list text-center mb0">
        <li>
          <span>Porudzbina</span>
          <span className="sum_price">{total} rsd</span>
        </li>
        <li>
          <span>Poštarina</span>
          <Shipping shipping={shipping} />
        </li>
        <li className="total-price">
          <span>UKUPNO</span>
          <span>{total + shipping} rsd</span>
        </li>
      </ul>
    </React.Fragment>
  );
};

const OrderForm = ({ onAction = () => {} }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('Srbija');
  const [city, setCity] = useState('');
  const [address, setAddress] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [couponCode, setCouponCode] = useState('');
  const [note, setNote] = useState('');

  return (
    <OrderFormWrapper>
      <h3>Podaci za dostavu</h3>
      <form
        onSubmit={e => {
          e.preventDefault();
          onAction('onSubmit', {
            data: {
              firstName, lastName, phone, email, country, city, address,
              zipCode, couponCode, note
            },
          });
        }}
      >
        <div className="form-group">
          <label htmlFor="">Ime</label>
          <input
            name="firstName"
            type="text"
            className="form-control"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Prezime</label>
          <input
            name="lastName"
            type="text"
            className="form-control"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Broj Telefona</label>
          <input
            name="phone"
            type="text"
            className="form-control"
            value={phone}
            onChange={e => setPhone(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Email adresa</label>
          <input
            name="email"
            type="text"
            className="form-control"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </div>
        <legend>Adresa</legend>
        <div className="form-group">
          <label htmlFor="">Zemlja</label>
          <input
            name="country"
            type="text"
            className="form-control"
            value={country}
            onChange={e => setCountry(e.target.value)}
            readOnly
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Grad</label>
          <input
            name="city"
            type="text"
            className="form-control"
            value={city}
            onChange={e => setCity(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Adresa</label>
          <input
            name="address"
            type="text"
            className="form-control"
            value={address}
            onChange={e => setAddress(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Poštanski kod</label>
          <input
            name="zipCode"
            type="text"
            className="form-control"
            placeholder="Ako znaš poštanski kod, unesi ga ovde"
            value={zipCode}
            onChange={e => setZipCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Imaš Kupon?</label>
          <input
            name="couponCode"
            type="text"
            className="form-control"
            placeholder="Ako imaš kupon, unesi ovde kod"
            value={couponCode}
            onChange={e => setCouponCode(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="">Napomena</label>
          <textarea
            name="note"
            type="text"
            style={{width: '100%', height: 127}}
            className="form-control"
            placeholder="Ako imaš napomenu, unesi je ovde"
            value={note}
            onChange={e => setNote(e.target.value)}
          ></textarea>
        </div>
        <input type="submit" className="btn btn-primary" value="Poruči" />
      </form>
    </OrderFormWrapper>
  );
};

export { ProductsList, OrderForm };
